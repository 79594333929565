
.no-webp .mais-vendidos{
    min-height: 85vh;
    padding-bottom: 80px;
    width: 100%;
    background-color: #000;
    background: url('../../../assets/images/bg-mais-vendidos.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; 
}
.webp .mais-vendidos{
    min-height: 85vh;
    padding-bottom: 80px;
    width: 100%;
    background-color: #000;
    background: url('../../../assets/images/bg-mais-vendidos.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; 
}

.mais-vendidos h2{
    padding-top: 80px !important;
    text-align: center;
    color: #fff;
    letter-spacing: 20px;
    padding-bottom: 80px;
}

.mais-vendidos .title-section::before{
    content: "";
    background-color: rgba(255, 255, 255, 0.322);
    height: 10px;
    width: 800px;
    position: absolute;
    left: 50%;
    transform: translate(-51%, 150%)
}

.mais-vendidos .card{
    border-radius: 0px;
    margin: 10px;
}

.mais-vendidos .card .card-body{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}



.mais-vendidos .card .btn-black{
    padding: 2px 30px;
    text-transform: uppercase;
}

.mais-vendidos .card .card-title{
    font-size: 16px;
    min-height: 70px;
}

.mais-vendidos .star-rating{
    display: flex;
    justify-content: flex-end;
}

@media (min-width: 768px) and (max-width: 1023px){
    .mais-vendidos .container-custom{
        padding-left: 50px;
        padding-right: 50px;
    }
    .mais-vendidos h2{
        letter-spacing: 12px;
    }

    .mais-vendidos .title-section::before{
        width: 656px;
    }
}

@media(min-width: 425px) and (max-width: 767px){
    .mais-vendidos .title-section::before{
        width: 400px;
    }
    
    .container-custom{
        padding-left: 20px;
        padding-right: 20px;
    }
    
    .mais-vendidos h2{
        padding-top: 60px !important;
        text-align: center;
        color: #fff;
        letter-spacing: 5px;
        padding-bottom: 60px;
        font-size: 24px;
    }

    .mais-vendidos .title-section::before{
        transform: translate(-51%, 101%);
    }
}


@media(min-width: 375px) and (max-width: 424px){
    .mais-vendidos .title-section::before{
        height: 10px;
        width: 340px;
        transform: translate(-50%, 84%);
    }

    .mais-vendidos h2{
        letter-spacing: 3px;
        padding-top: 50px !important;
        padding-bottom: 50px;
        font-size: 22px;
    }

    .container-custom{
        padding-left: 20px;
        padding-right: 20px;
    }
    
}

@media (max-width: 374px){
    .mais-vendidos .title-section::before{
        width: 296px;
        transform: translate(-50%, 82%);
    }
    .mais-vendidos h2{
        font-size: 20px;
        padding-top: 40px !important;;
        letter-spacing: 2px;
        padding-bottom: 40px;
    }
    .container-custom{
        padding-left: 20px;
        padding-right: 20px;
    }
    
}

@media(max-height: 780px){
    .mais-vendidos .card{
        min-height: 460px;
    }
}

@media(min-width: 426px) and (max-width: 768px){
    .no-webp .mais-vendidos{
        background: url('../../../assets/images/bg-mais-vendidos@768px.jpg');
    }
    .webp .mais-vendidos{
        background: url('../../../assets/images/bg-mais-vendidos@768px.webp');
    }
}

@media(max-width: 425px){
    .no-webp .mais-vendidos{
        background: url('../../../assets/images/bg-mais-vendidos@425px.jpg');
    }
    .webp .mais-vendidos{
        background: url('../../../assets/images/bg-mais-vendidos@425px.webp');
    }
}
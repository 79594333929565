.card{
    min-height: 550px;
}

.produto-valor .valor-novo{
    font-weight: 700;
    font-size: 20px;
}


.valor-antigo span.inner {
    color: #000;
    font-weight: 500;
}
.valor-antigo span.outer {
    color: rgb(121, 121, 121);
    text-decoration: line-through;
    font-weight: 500;
}

.carregando-img{
    width: 100%;
    height: 380px;
    background: rgb(240, 240, 240);
    display: flex;
    justify-content: center;
    align-items: center;
}

.carregando-img i{
    color: rgb(109, 109, 109);
}


.carregando-title p{
    background: rgb(226, 226, 226);
    width: 80%;
    height: 12px;
    border-radius: 5px;
}

.carregando-valor{
    background: rgb(226, 226, 226);
    width: 80%;
    height: 12px;
    border-radius: 8px;
}
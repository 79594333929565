
.banner-home .text-content{
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
}

.banner-home .container, .banner-home .row{
    height: 100%;
}


.banner-home .text-content .text-banner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    letter-spacing: .8rem;
    color: #fff;
    font-size: 21px;
    margin-top: -20px;
}


.banner-home .vertical-logo{
    width: 400px;
    height: auto;
}

.banner-home .vertical-logo img{
    width: 100%;
}


@media(min-width: 1024px) and (max-width: 1439px){
    .banner-home .text-content .text-banner{
        font-size: 17px;
    }
}



@media(max-width: 1023px){
    .banner-home .banner-home {
        background: rgba(0,0,0,0.5);
    }
}

@media(min-width: 425px) and (max-width:767px){
    .banner-home .text-content .text-banner{
        letter-spacing: 0.4rem;
        font-size: 17px;
    }

    .banner-home .vertical-logo{
        width: 280px;
        height: auto;
    }
}

@media(max-width: 424px){
    .banner-home .text-content .text-banner{
        letter-spacing: 0.4rem;
        font-size: 17px;
    }

    .banner-home .vertical-logo{
        width: 230px;
        height: auto;
    }
}
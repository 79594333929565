@font-face {
    font-family: ATSackersGothic;
    src: url('./assets/fonts/at-sackers-gothic-medium.ttf');
    font-display: swap;
  }
  
  @font-face {
    font-family: HelveticaNeue;
    src: url('./assets/fonts/helvetica-neue.ttf');
    font-display: swap;
  }
  
  
  button, button div, .nav-item, .card, .depoimento-content, .blog-content, .diferencial{
    font-family: 'HelveticaNeue', sans-serif !important;
  }
  
  body {margin: 0px !important;font-family: 'ATSackersGothic', sans-serif !important;}
  
  .container-custom{
    padding-left: 100px;
    padding-right: 100px;
  }
  
  .btn-black{
    background: #000 !important;
    color: #fff !important;
    border-radius: 0px !important;
  }
  .btn-black:hover{
   filter: brightness(0.8);
  }
  
  .star-container{
    padding: 0px !important;
  }
  
  .star-container .widget-svg{
    width: 15px !important;
    height: 15px !important;
  }
  
  
  h2{
    text-transform: uppercase;
  }
  
  .btn-outline{
    border-radius: 0px !important;
    border: 2px solid #fff  !important;
    padding: 5px  !important;
  }
  
  .btn-outline:hover{
    filter: brightness(0.7)
  }
  
  .btn-outline div{
    background-color: #fff;
    padding: 5px 20px;
    text-transform: uppercase;
  }

  @media(min-width: 1024px) and (max-width: 1439px){
    .container-custom{
      padding-left: 80px;
      padding-right: 80px;
      
    }
  }

  @media(min-width: 768px) and(max-width: 1023px) {
	  .container-custom{
		padding-left: 50px;
		padding-right: 50px;
	  }
  }
.welcome-textos{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    background: #eef1f3;
    font-family: 'HelveticaNeue', sans-serif !important;
}

.welcome-textos .texto{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 15.7px;
    color: #7e7e7e;
}
.welcome-textos .texto h5{
    font-weight: bold;
    color: #000;
}

.welcome-textos .texto-icon{
    background: rgb(223, 223, 223);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.welcome-textos .texto-icon img{
    width: 50%;
}

@media(max-width: 767px){
    .welcome-textos{
        padding: 50px 10px; 
    }
}
.newsletter{
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 80px;
}

.newsletter h2:first-child{
    padding-top: 80px;
    text-align: center;
    color: #000;
    letter-spacing: 20px;
    padding-bottom: 0px !important;
    margin-bottom: 0px;
}
.newsletter .title-section:first-child::before{
    content: "";
    background-color: rgba(141, 141, 141, 0.432);
    height: 10px;
    width: 700px;
    position: absolute;
    left: 50%;
    transform: translate(-50.5%, 150%);
    z-index: -1;
}

.newsletter h2{
    text-align: center;
    color: #000;
    letter-spacing: 20px;
    padding-bottom: 80px;
}


.newsletter .title-section::before{
    content: "";
    background-color: rgba(141, 141, 141, 0.432);
    height: 10px;
    width: 1300px;
    position: absolute;
    left: 50%;
    transform: translate(-50.5%, 150%);
    z-index: -1;
}

.newsletter input{
    font-family: 'HelveticaNeue', sans-serif !important;
    border-radius: 0px !important;
    border: 1px solid #000;
}

.newsletter .form-newsletter{
    display: flex;
    justify-content: center;
}


@media(min-width: 1024px) and (max-width: 1439px){
    .newsletter .title-section:first-child::before{
        transform: translate(-50.5%, 120%)
    }
    .newsletter h2{
        font-size: 26px;
        letter-spacing: 15px;
    }
    .newsletter .title-section::before{
        width: 1000px;
        transform: translate(-50.5%, 122%);
    }   
    
}
@media(min-width: 768px) and (max-width: 1023px){
    .newsletter .title-section:first-child::before{
        transform: translate(-50.5%, 100%);
    }

    .newsletter .title-section::before{
        width: 750px;
        transform: translate(-50.5%, 100%)
    } 

    .newsletter h2{
        letter-spacing: 8px;
        font-size: 24px;
    }
}

@media(min-width: 425px) and (max-width: 767px){
    .newsletter .title-section:first-child::before{
        width: 380px;
        transform: translate(-50.5%, 80%);
    }

    .newsletter .title-section::before{
        width: 400px;
        transform: translate(-50.5%, 80%)
    } 

    .newsletter h2{
        letter-spacing: 5px;
        font-size: 20px;
    }

    .newsletter h2:first-child{
        padding-top: 60px;
        letter-spacing: 5px;
    }
    .newsletter{
        padding-bottom: 60px;
    }
}


@media(min-width: 375px) and (max-width: 424px){
    .newsletter .title-section:first-child::before{
        width: 320px;
        transform: translate(-50.5%, 66%);
    }

    .newsletter .title-section::before{
        width: 360px;
        transform: translate(-50.5%, 66%)
    } 

    .newsletter h2{
        letter-spacing: 4px;
        font-size: 18px;
        padding-bottom: 60px;
    }

    .newsletter h2:first-child{
        padding-top: 60px;
        letter-spacing: 4px;
    }
    .newsletter{
        padding-bottom: 60px;
    }
}

@media(max-width: 374px){
    .newsletter .title-section:first-child::before{
        width: 260px;
        transform: translate(-50.5%, 66%);
    }

    .newsletter .title-section::before{
        width: 300px;
        transform: translate(-50.5%, 66%)
    } 

    .newsletter h2{
        letter-spacing: 3px;
        font-size: 16px;
        padding-bottom: 40px;
    }

    .newsletter h2:first-child{
        padding-top: 40px;
        letter-spacing: 3px;
    }
    .newsletter{
        padding-bottom: 40px;
    }
}

.whatsapp-icon{
    width: 60px;
    height: 60px;
    padding: 10px;
    background-color: #4dc247;
    position: fixed;
    bottom: 10px;
    right: 10px;
    box-shadow: 3px 3px 12px #00000033;
    border-radius: 50%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001;
}

.whatsapp-icon i{
    font-size: 40px;
    color: #fff;
}

.whatsapp-icon i:hover{
    filter: brightness(0.9);
}
.no-webp .blog{
    min-height: 80vh;
    padding-bottom: 80px;
    width: 100%;
    background: url('../../../assets/images/bg-mais-vendidos.jpg');
    background-color: #000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; 
}

.webp .blog{
    min-height: 80vh;
    padding-bottom: 80px;
    width: 100%;
    background: url('../../../assets/images/bg-mais-vendidos.webp');
    background-color: #000;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; 
}


.blog h2{
    padding-top: 80px !important;
    text-align: center;
    color: #fff;
    letter-spacing: 20px;
    padding-bottom: 80px;
}

.blog a {
    color: #fff;
    text-decoration: none;
}

.blog a:hover{
    filter: brightness(0.7);
    color: #ccc;
}

.blog .title-section::before{
    content: "";
    background-color: rgba(255, 255, 255, 0.322);
    height: 10px;
    width: 600px;
    position: absolute;
    left: 50%;
    transform: translate(-51%, 150%);
}

.blog .blog-content{
    padding: 0px 26px;
    color: #fff;
}

.blog .blog-content img{
    width: 100%;
    height: 366px;
    object-fit: cover;
}

.blog .blog-content .blog-desc{
    margin-top: 20px;
    font-size: 24px;
    text-transform: uppercase;
}

.blog .blog-content .blog-date{
    color: #8d8d8d;
}

@media(min-width: 1024px) and (max-width: 1439px){
    .blog .blog-content .blog-desc{
        font-size: 22px;
    }

    .blog{
        min-height: 75vh;
    }
}

@media(min-width: 425px) and (max-width: 767px){
    .blog .title-section::before{
        width: 400px;
        transform: translate(-51%, 100%);
    }

    .blog h2{
        padding-top: 60px !important;
        padding-bottom: 60px;
        font-size: 24px;
        letter-spacing: 5px;
    }
    .blog .blog-content .blog-desc{
        font-size: 20px;
    }
}

@media(min-width: 375px) and (max-width: 424px){
    .blog .title-section::before{
        width: 320px;
        transform: translate(-50%, 94%);
    }

    .blog h2{
        letter-spacing: 3px;
        padding-top: 50px !important;
        padding-bottom: 50px;
        font-size: 24px;
    }

    .blog .blog-content .blog-desc{
        font-size: 17px;
    }
}

@media(max-width: 374px){
    .blog .title-section::before{
        width: 280px;
        transform: translate(-50%, 94%);
    }

    .blog h2{
        letter-spacing: 3px;
        padding-top: 40px !important;
        padding-bottom: 40px;
        font-size: 24px;
    }

    .blog .blog-content .blog-desc{
        font-size: 14px;
    }
}


@media(min-width: 426px) and (max-width: 768px){
    .no-webp .blog{
        background: url('../../../assets/images/bg-mais-vendidos@768px.jpg');
    }
    .webp .blog{
        background: url('../../../assets/images/bg-mais-vendidos@768px.webp');
    }
}

@media(max-width: 425px){
    .no-webp .blog{
        background: url('../../../assets/images/bg-mais-vendidos@425px.jpg');
    }
    .webp .blog{
        background: url('../../../assets/images/bg-mais-vendidos@425px.webp');
    }
}
.diferenciais{
    background: #eef1f3;
    padding-bottom: 50px;
}

.diferenciais h2{
    padding-top: 80px !important;
    text-align: center;
    color: #000;
    letter-spacing: 20px;
    padding-bottom: 80px;
}

.diferenciais .title-section::before{
    content: "";
    background-color: rgba(141, 141, 141, 0.432);
    height: 10px;
    width: 1100px;
    position: absolute;
    left: 50%;
    transform: translate(-51%, 150%)
}

.diferenciais .diferencial{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.diferenciais .diferencial .diferencial-desc{
    color: #959495; 
    padding: 0 30px;
}

.diferenciais .diferencial .diferencial-title{
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 20px;
}


@media(min-width: 1024px) and (max-width: 1439px){
    .diferenciais .title-section::before{
        width: 940px;
        transform: translate(-51%, 98%);
    }

    .diferenciais h2{
        font-size: 24px;
    }
}

@media(min-width: 768px) and (max-width: 1023px){
    .diferenciais .title-section::before{
        width: 740px;
        transform: translate(-50.5%, 140%);
    }

    .diferenciais h2{
        letter-spacing: 12px;
    }
}

@media(min-width: 425px) and (max-width: 767px){
    .diferenciais .title-section::before{
        width: 400px;
        transform: translate(-50.5%, 78%);
    }

    .diferenciais h2{
        padding-top: 60px !important;
        padding-bottom: 60px;
        font-size: 20px;
        letter-spacing: 5px
    }
}

@media(min-width: 375px) and (max-width: 424px){
    .diferenciais .title-section::before{
        width: 350px;
        transform: translate(-50.5%, 74%);
    }

    .diferenciais h2{
        padding-top: 50px !important;
        padding-bottom: 50px;
        font-size: 18px;
        letter-spacing: 3px
    }
}

@media(max-width: 374px){
    .diferenciais .title-section::before{
        width: 280px;
        transform: translate(-50.5%, 56%);
    }

    .diferenciais h2{
        padding-top: 40px !important;
        padding-bottom: 40px;
        font-size: 16px;
        letter-spacing: 2px
    }
}
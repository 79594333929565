footer {
    background: #eef1f3;
    padding-top: 100px;
    padding-bottom: 50px;
    font-family: 'HelveticaNeue', sans-serif !important;
}

footer h3{
    font-size: 18px;
    margin-bottom: 30px;
    letter-spacing: 4px;
    font-weight: bold;
    text-transform: uppercase;
}

footer .footer-social{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
}

footer .social-icons{
    margin-top: 30px;
    font-size: 30px;
}

footer .social-icons i{
    margin-left: 20px;
    margin-right: 20px;
}

footer ul{
    padding-left: 0px;
    list-style: none !important;
}

footer ul li{
    margin-bottom: 15px;
}

footer .help-col{
    padding-right: 0px;
}

footer .sugestoes i{
    font-size: 20px;
    margin-right: 20px;
}

footer .copyright{
    margin-top: 30px;
    text-align: center;
    font-size: 20px;
}

footer a{
    text-decoration: none !important;
    color: var(--grey-color);
}

footer a:hover{
    color: rgb(49, 49, 49);
}


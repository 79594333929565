.depoimentos{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
    background: #eef1f3;
}

.depoimentos h2{
    padding-top: 80px !important;
    text-align: center;
    color: #000;
    letter-spacing: 20px;
    padding-bottom: 80px;
}

.depoimentos .title-section::before{
    content: "";
    background-color: rgba(141, 141, 141, 0.432);
    height: 10px;
    width: 1100px;
    position: absolute;
    left: 50%;
    transform: translate(-51%, 150%)
}

.depoimentos .depoimento-content{
    display: flex;
    justify-content: center;
    align-items: center; 
    flex-direction: column;
}

.depoimentos .img-depoimento{
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
}

.depoimentos .texto-depoimento{
    text-align: center;
    margin-top: 30px;
    color: #989898;
    font-size: 14px;
    margin-bottom: 0px;
    padding: 0px 150px;
}

.depoimentos .cliente-depoimento{
    margin-top: 20px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0px;
}

.depoimentos .cargo-depoimento{
    color: #989898;
}

@media(min-width: 1024px) and (max-width: 1439px){
    .depoimentos .title-section::before{
        width: 930px;
    }

    .depoimentos h2{
        letter-spacing: 15px;
        font-size: 30px;
    }
}

@media(min-width: 768px) and (max-width: 1023px){
    .depoimentos .title-section::before{
        width: 720px;
        transform: translate(-51%, 110%);
    }

    .depoimentos h2{
        letter-spacing: 10px;
        font-size: 25px;
        padding-top: 60px !important;
        padding-bottom: 60px;
    }
    .depoimentos .depoimento-content{
        padding-left: 20px;
        padding-right: 20px;
    }
    .depoimentos .texto-depoimento{
        text-align: center;
        padding: 0px 40px;
    }
}

@media(min-width: 425px) and (max-width: 767px){
    .depoimentos .title-section::before{
        width: 390px;
        transform: translate(-51%, 110%);
    }

    .depoimentos h2{
        letter-spacing: 5px;
        font-size: 25px;
        padding-top: 40px !important;
        padding-bottom: 40px;
    }
    .depoimentos .depoimento-content{
        padding-left: 20px;
        padding-right: 20px;
    }
    .depoimentos .texto-depoimento{
        text-align: center;
        padding: 0px 20px;
    }
}

@media(min-width: 375px) and (max-width: 424px){
    .depoimentos .title-section::before{
        width: 350px;
        transform: translate(-51%, 100%);
    }

    .depoimentos h2{
        letter-spacing: 3px;
        font-size: 22px;
        padding-top: 40px !important;
        padding-bottom: 40px;
    }
    .depoimentos .depoimento-content{
        padding-left: 20px;
        padding-right: 20px;
    }
    .depoimentos .texto-depoimento{
        text-align: center;
        padding: 0px 8px;
    }
}

@media(max-width: 374px) {
    .depoimentos .title-section::before{
        width: 300px;
        transform: translate(-51%, 90%);
    }

    .depoimentos h2{
        letter-spacing: 2px;
        font-size: 20px;
        padding-top: 40px !important;
        padding-bottom: 40px;
    }
    .depoimentos .depoimento-content{
        padding-left: 20px;
        padding-right: 20px;
    }
    .depoimentos .texto-depoimento{
        text-align: center;
        padding: 0px 4px;
    }
}
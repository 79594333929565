.main-navbar {
    position: sticky;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 1000;
}

.navbar-brand {
    width: 30vw;
}

.main-navbar .navbar-brand img {
    width: 70%;
    height: auto;
}


.main-navbar .nav-item {
    font-size: 1.3rem;
    margin-right: 1vw;
}


.main-navbar .baixar-catalogo {
    background: #cccc;
    display: flex;
    
        align-items: center;
    padding: 2px 10px;
    border-radius: 20px;
    flex-direction: row;
}

.main-navbar .baixar-catalogo i {
    margin: 0 10px;
}


@media(max-width: 1024px) {
    .main-navbar .navbar-brand img {
        margin-left: 0px;
    }
}


@media(max-width: 767px) {
    .main-navbar .navbar-brand {
        display: none;
    }
}

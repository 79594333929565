.no-webp .produtos-personalizados{
    height: 60vh;
    width: 100%;
    background: url('../../../assets/images/bg-produtos-personalizados.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; 
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.webp .produtos-personalizados{
    height: 60vh;
    width: 100%;
    background: url('../../../assets/images/bg-produtos-personalizados.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative; 
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}

@font-face {
    font-family: "Assistant";
    src: url("../../../assets/fonts/Assistant-Regular.ttf");
    font-display: swap;
}


.produtos-personalizados .title-section{
    width: 100%;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    padding-bottom: 20px;
    padding-top: 20px;
    text-align: center;
    color: #fff;
    backdrop-filter: blur(5px);
}

.produtos-personalizados a{
    color: #000 !important;
    text-decoration: none;
}

.produtos-personalizados .title-section h2{
    letter-spacing: 25px;
    line-height: 70px;
}

@media(min-width: 1024px) and (max-width: 1439px){
    .produtos-personalizados .title-section h2{
        letter-spacing: 17px;
    }
}

@media(min-width: 768px) and (max-width: 1023px){
    .produtos-personalizados .title-section h2 {
        letter-spacing: 8px;
        line-height: 56px;
        font-size: 28px;
    }
    .produtos-personalizados{
        height: 56vh;
    }
}

@media(min-width: 425px) and (max-width: 767px){
    .produtos-personalizados .title-section h2 {
        letter-spacing: 4px;
        line-height: 40px;
        font-size: 24px;
    }

    .produtos-personalizados{
        height: 50vh;
    }
}

@media(min-width: 375px) and (max-width: 424px){
    .produtos-personalizados .title-section h2 {
        letter-spacing: 4px;
        line-height: 30px;
        font-size: 20px;
    }

    .produtos-personalizados{
        height: 45vh;
    }
}

@media(max-width: 374px){
    .produtos-personalizados .title-section h2 {
        letter-spacing: 2px;
        line-height: 30px;
        font-size: 16px;
    }

    .produtos-personalizados{
        height: 45vh;
    }
}

@media(min-width: 426px) and (max-width: 768px){
    .no-webp .produtos-personalizados{
        background: url('../../../assets/images/bg-produtos-personalizados@768px.jpg');
    }
    .webp .produtos-personalizados{
        background: url('../../../assets/images/bg-produtos-personalizados@768px.webp');
    }
}

@media(max-width: 425px){
    .no-webp .produtos-personalizados{
        background: url('../../../assets/images/bg-produtos-personalizados@425px.jpg');
    }
    .webp .produtos-personalizados{
        background: url('../../../assets/images/bg-produtos-personalizados@425px.webp');
    }
}